<template>
  <div class="content">
    <strong>Taxas:</strong><br/>
    {{ rowData.taxas }}
  </div>
</template>

<script>
/* eslint-disable camelcase */
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

export default {
  name: 'detail-row-check-sell',
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    },
    options: {
      type: Object
    }
  }
}
</script>

<style scoped>
  .content {
    margin-top: 5px;
    margin-bottom: 20px;
  }
</style>
